/* eslint-disable require-jsdoc */
import { Backdrop, CircularProgress } from '@mui/material';
import { useSession } from 'next-auth/react';
import Router, { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAuth } from '../../components/Providers/Auth';

const LoginPage = () => {
  const session = useSession();
  const { login } = useAuth();
  const router = useRouter();

  const { callbackUrl } = router.query;

  useEffect(() => {
    if (!['authenticated', 'loading'].includes(session.status)) {
      login('keycloak');
    } else if (typeof callbackUrl === 'string') {
      Router.replace(new URL(callbackUrl));
    }
  }, [callbackUrl, login, session]);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={
        session.status === 'loading' || session.status === 'unauthenticated'
      }
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoginPage;
